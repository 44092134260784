import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import logo from '../../src/assetsgeneral/images/logo.png'
import { useSelector, useDispatch } from 'react-redux'
import { tabHome, tabAboutUs, tabOurPlans, tabWeWork, tabContactUs } from '../../src/action/tabActions'
const NavBar = () => {
    const myState = useSelector((state => state.changeTheTab))
    const scrollToAboutUs = () => {
        const aboutUsSection = document.getElementById('about-us-section');
        if (aboutUsSection) {
          aboutUsSection.scrollIntoView({ behavior: 'smooth' });
        }
      };
      
      const scrollToFooter = () => {
        const footerSection = document.getElementById('footer');
        if (footerSection) {
          footerSection.scrollIntoView({ behavior: 'smooth' });
        }
      };
    console.log("***********", myState.viewNavBar)
    const dispatch = useDispatch()

    const [showDropDown, setshowDropDown] = useState(false)
    const openDropDown = () => {
    setshowDropDown((showDropDown) => !showDropDown)
}
console.log(showDropDown)

    return (
        <>
            <div className="">
            {/* <div className="riyo-header-section"> */}
                <div className="container">
                    <div className="row">   
                        <div className="col-md-12">
                            <div className="riyo-header-img">
                                <nav className="navbar sticky-top navbar-expand-lg bg-dark">
                                    <div className="container">
                                        <NavLink className="navbar-brand" to="/"><img src={logo} id="logsedf" style={{height: "95px", marginLeft: "75px"}}/></NavLink>
                                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                            <i className="fa fa-bars" aria-hidden="true"></i>
                                        </button>
                                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                        {/* {
                                            myState.viewNavBar === "aboutus" ?
                                            <>
                                            <ul className="navbar-nav">
                                                                                            
                                                <li className="nav-item">
                                                    <NavLink className="nav-link" onClick={tabAboutUs} id="home-col" to="#about-us">About Us</NavLink>
                                                </li>
                                                
                                                <li className="nav-item">
                                                    <NavLink className="nav-link" onClick={tabContactUs} to="#contact">Contact Us</NavLink>
                                                </li>
                                                
                                            </ul>
                                            </>      
                                            : 
                                            myState.viewNavBar === "contactus" ?
                                            <>
                                            <ul className="navbar-nav">
                                                
                                                <li className="nav-item">
                                                    <NavLink className="nav-link" onClick={tabAboutUs} to="#about-us">About Us</NavLink>
                                                </li>
                                                
                                                <li className="nav-item">
                                                    <NavLink className="nav-link" onClick={tabContactUs} id="home-col"  to="#contact">Contact Us</NavLink>
                                                </li>
                                                
                                            </ul>
                                            </>      
                                            : 
                                           <div>Loading</div>
                                        } */}
                                        {myState.viewNavBar === "aboutus" ? (
                                            <>
                                            <ul className="navbar-nav">
                                                <li className="nav-item">
                                                <NavLink className="nav-link" onClick={scrollToAboutUs} id="home-col" to="#about-us">About Us</NavLink>
                                                </li>
                                                <li className="nav-item">
                                                <NavLink className="nav-link" onClick={scrollToFooter} to="#contact">Contact Us</NavLink>
                                                </li>
                                            </ul>
                                            </>
                                        ) : myState.viewNavBar === "contactus" ? (
                                            <>
                                            <ul className="navbar-nav">
                                                <li className="nav-item">
                                                <NavLink className="nav-link" onClick={scrollToAboutUs} to="#about-us">About Us</NavLink>
                                                </li>
                                                <li className="nav-item">
                                                <NavLink className="nav-link" onClick={scrollToFooter} id="home-col" to="#contact">Contact Us</NavLink>
                                                </li>
                                            </ul>
                                            </>
                                        ) : (
                                            <div>Loading</div>
                                        )}
                                        </div>
                                    </div>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default NavBar