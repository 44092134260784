const initialState = {
    viewNavBar : "aboutus"

}
export const changeTheTab = (state = initialState, action) => {

    switch(action.type){
        // case "TAB_HOME" : return {
        //     ...state,
        //     viewNavBar : "home" 
        // }
        case "TAB_ABOUTUS" : return {
            ...state,
            viewNavBar : "aboutus"
        }
        // case "TAB_OURPLANS" : return {
        //     ...state,
        //     viewNavBar : "ourplans"
        // }

        // case "TAB_WEWORK" : return {
        //     ...state,
        //     viewNavBar : "wework"
        // }
        case "TAB_CONTACTUS" : return {
            ...state,
            viewNavBar : "contactus"
        }
        default :
            return state;
    }
}
