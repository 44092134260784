import React from 'react'
import AboutUsInfo from '../components/aboutUsComponents/AboutUsInfo'
import BannerAboutUs from '../components/aboutUsComponents/BannerAboutUs'
import Facts from '../components/aboutUsComponents/Facts'
import OurUsers from '../components/aboutUsComponents/OurUsers'
import Testimonials from '../components/aboutUsComponents/Testimonials'
import Footer from '../components/Footer'
import NavBar from '../components/NavBar'
import OrderNow from '../components/aboutUsComponents/OrderNow'

const AboutUs = () => {
  return (
    <>
    <NavBar />
    <BannerAboutUs />
    <OrderNow />
    <AboutUsInfo />
    {/* <Facts /> */}
    {/* <Testimonials /> */}
    <OurUsers />
    <Footer />
    </>
  )
}

export default AboutUs