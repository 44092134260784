import React from 'react'
import footerlogo from '../assetsgeneral/images/footerlogo.png'
import { Link } from 'react-router-dom'
const Footer = () => {
    return (
        <>
            <div className="footer-section" id="footer">
                <div className="container">
                    <div className="row">
                        <div className="col-md-3">
                            <div className="footer-logo">
                                <a href="/"> <img src={footerlogo} id="img-wa" style={{width: "80px"}} /> </a>
                            </div>
                        </div>
                        <div className="col-md-5">
                            <div className="footer-logo">
                                {/* <a href="#"> <img src={footerlogo} id="img-wa" /> </a> */}
                                <ul>
                                    {/* <li> <a href="#"> <i className="fa fa-facebook" aria-hidden="true"></i> </a> </li> */}
                                    <li> <a href="https://www.instagram.com/phatthaise19?igsh=MWIzMHNvb2g5Ym1qcA=="> <i className="fa fa-instagram" aria-hidden="true"></i>  </a> </li>
                                    {/* <li> <a href="#"> <i className="fa fa-twitter" aria-hidden="true"></i> </a> </li> */}
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="footer-logo-text">
                                <h2> Contact Us </h2>
                                <ul>
                                    {/* <li> <a href="#"> <i className="fa fa-map-marker" aria-hidden="true"></i> 8200 Humboldt Ave s sUITE #221 Bloomington, MN, 55431  </a> </li> */}
                                    <li> <a href="#"> <i className="fa fa-envelope" aria-hidden="true"></i> Info@phatthai.co.uk</a> </li>
                                    {/* <li> <a href="#">  <i className="fa fa-phone" aria-hidden="true"></i> (612) 423-2745 </a> </li> */}
                                </ul>
                            </div>
                        </div>
                        
                        
                        <div className="col-md-12">
                            <div className="footer-end-se">
                                <p> Copyrights 2024 PhatThai. All Rights Reserved. Developed and Designed By 
                                    <a href="https://systemdecoding.com" target="_blank"> System Decoding</a> </p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default Footer