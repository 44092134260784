import React from 'react'
import s1 from '../../assetsgeneral/images/s1.png'
import s2 from '../../assetsgeneral/images/s2.png'
import s3 from '../../assetsgeneral/images/s3.png'
import about2 from '../../assetsgeneral/images/aboutus.png'
import { AnimationOnScroll } from 'react-animation-on-scroll';
import CountUp from 'react-countup';

const OrderNow = () => {
    return (
        <>
            <div className="our-solutin-section" id="orderNow">
                <h2 style={{ fontFamily: "Georgia, serif", marginLeft: "80px" }}>Order Now</h2>
                <div className="container" >
                    <div className="row">
                        {/* <div className="col-md-4">
                            <a href="https://www.just-eat.co.uk/restaurants-phat-thai-upper-norwood/menu" className="oursolut-iconss">
                                <img src={s3} id="img1new" />
                                <div>Just Eat </div>
                            </a>
                            <div className="ousoltext1"> 
                                <br />
                            </div>
                        </div>

                        <div className="col-md-4">
                            <a href="https://www.ubereats.com/gb/store/phat-thai/lHWLIVxnQyeYx0C1txxChA?diningMode=DELIVERY&ps=1&sc=SEARCH_SUGGESTION" className="oursolut-iconss">
                                <img src={s2} id="img1new" />
                                <div style={{ marginLeft: '10px',fontFamily: "Georgia, serif"}}>Uber Eats</div>
                            </a>
                            <div className="ousoltext1">
                                <br />
                            </div>
                        </div>
                        <div className="col-md-4">
                            <a href="https://deliveroo.co.uk/menu/London/norwood/phat-thai-crystal-palace?geohash=gcpuw14tsj1h" className="oursolut-iconss">
                                <img src={s1} id="img1new" />
                                <div>Deliveroo </div>

                            </a>
                            <div className="ousoltext1">
                                <br />
                            </div>
                        </div> */}
                        <div className="col-md-4">
                            <div className="columns">
                                <ul className="price">
                                    <li className="header">
                                    <a href="https://www.just-eat.co.uk/restaurants-phat-thai-upper-norwood/menu" className="oursolut-iconss">
                                        <img src={s3} id="img1new" />
                                    </a> 
                                    </li>
                                    <li className="grey">
                                        <a href="https://www.just-eat.co.uk/restaurants-phat-thai-upper-norwood/menu" className="button">Order Now</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="columns">
                                <ul className="price">
                                    <li className="header">
                                    <a href="https://www.ubereats.com/gb/store/phat-thai/lHWLIVxnQyeYx0C1txxChA?diningMode=DELIVERY&ps=1&sc=SEARCH_SUGGESTION" className="oursolut-iconss">
                                        <img src={s2} id="img1new" />
                                    </a> 
                                    </li>
                                    <li className="grey">
                                        <a href="https://www.ubereats.com/gb/store/phat-thai/lHWLIVxnQyeYx0C1txxChA?diningMode=DELIVERY&ps=1&sc=SEARCH_SUGGESTION" className="button">Order Now</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="columns">
                                <ul className="price">
                                    <li className="header">
                                    <a href="https://deliveroo.co.uk/menu/London/norwood/phat-thai-crystal-palace?geohash=gcpuw14tsj1h" className="oursolut-iconss">
                                        <img src={s1} id="img1new" />
                                    </a>
                                    </li>
                                    <li className="grey">
                                        <a href="https://deliveroo.co.uk/menu/London/norwood/phat-thai-crystal-palace?geohash=gcpuw14tsj1h" className="button">Order Now</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default OrderNow