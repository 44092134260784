import React from 'react'
import about from  '../../assetsgeneral/images/about.png'
import about2 from  '../../assetsgeneral/images/about2.png'

const BannerAboutUs = () => {
    const scrollToOrder = () => {
        const orderNowSection = document.getElementById('orderNow');
        if (orderNowSection) {
            orderNowSection.scrollIntoView({ behavior: 'smooth' });
        }
      };
    return (
        <>
            <div className="">
                <div className="container" style={{backgroundImage: `linear-gradient(rgba(255,255,255,0.5), rgba(255,255,255,0.5)), url(${about})`, backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat', borderRadius: '20px'}}>
                    <div className="row">
                    {/* <div className="col-md-4">
                            <div className="work-schedule-img">
                                <img src={about2} id="sli-img-se" style={{borderRadius: "30px 0 0 30px"}}/>
                            </div>
                        </div> */}
                        <div className="col-md-7">
                            <div className="work-schedule">
                                <br />
                                <br />
                                <br />
                                <br />
                                <h2>Discover the </h2>
                                <h3 style={{fontFamily: "Georgia, serif"}}> Best Food.</h3>
                            </div>
                            <li className="grey">
                                <a href="#order-now" className="button" onClick={scrollToOrder}>ORDER NOW</a>
                            </li>
                        </div>
                        <div className="col-md-4">
                            <div className="work-schedule-img">
                                <img src={about2} id="sli-img-se" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default BannerAboutUs