import React from 'react'
import s1 from '../../assetsgeneral/images/s1.png'
import s2 from '../../assetsgeneral/images/s2.png'
import s3 from '../../assetsgeneral/images/s3.png'
import about2 from '../../assetsgeneral/images/aboutus.png'
import { AnimationOnScroll } from 'react-animation-on-scroll';
import CountUp from 'react-countup';

const OurUsers = () => {
    return (
        <>
            <div className="our-solutin-section">
                <h2 style={{fontFamily: "Georgia, serif", marginLeft: "80px"}}> Our Partners</h2>
                <div className="container" style={{backgroundImage: ` url(${about2})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}}>
                    <div className="row">
                        <div className="col-md-4">
                            {/* <AnimationOnScroll animateIn="fadeInUp" duration="1.5" animateOnce="true"> */}
                            <a className="oursolut-iconss2">
                                <img src={s3} id="img1" />
                            </a>
                            <div className="ousoltext1">
                                <br />
                                {/* <h2>Just Eat </h2> */}
                            </div>
                            {/* </AnimationOnScroll> */}
                        </div>
                        
                        <div className="col-md-4">
                            {/* <AnimationOnScroll animateIn="fadeInUp" duration="1.5" animateOnce="true"> */}
                            <a className="oursolut-iconss2">
                                <img src={s2} id="img1" />
                            </a>
                            <div className="ousoltext1">
                                <br />
                                {/* <h2>Uber Eats </h2> */}
                            </div>
                            {/* </AnimationOnScroll> */}
                        </div>
                        
                        <div className="col-md-4">
                            {/* <AnimationOnScroll animateIn="fadeInUp" duration="1.5" animateOnce="true"> */}
                            <a className="oursolut-iconss2">
                                <img src={s1} id="img1" />
                            </a>
                            <div className="ousoltext1">
                                <br />
                                {/* <h2>Deliveroo </h2> */}
                            </div>
                            {/* </AnimationOnScroll> */}
                        </div>
                        {/* <div className="col-md-3">
                            <AnimationOnScroll animateIn="fadeInUp" duration="1.5" animateOnce="true">
                            <div className="oursolut-iconss2">
                                <img src={s4} id="img1" />
                            </div>
                            <div className="ousoltext1">
                            <CountUp start={0} end={238} delay={1} duration={2} enableScrollSpy="true">
                                            {({ countUpRef }) => (
                                <h3>
                                    <span ref={countUpRef} /><span></span>
                                </h3>
                                )}
                                </CountUp>
                                <h2>Companies </h2>
                            </div>
                            </AnimationOnScroll>
                        </div> */}
                    </div>
                </div>
            </div>
        </>
    )
}
export default OurUsers